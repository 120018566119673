/* Ride-booking form container with improved padding and background */
.ride-booking-form-container {
  display: flex;
  justify-content: space-between;
  padding: 40px;
  gap: 30px;
  background: linear-gradient(135deg, #1a1f27, #24303e); /* Darker, modern gradient */
  color: #e0e0e0;
  border-radius: 15px;
}

/* Subtle card container styling with a slightly lighter touch */
.card {
  background-color: rgba(255, 255, 255, 0.07); /* A tad lighter to increase readability */
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  border: 1px solid rgba(255, 255, 255, 0.15); /* Slightly more defined border */
  backdrop-filter: blur(12px);
}

/* Left and right containers for the form and map */
.ride-booking-left, .ride-booking-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Form styling with improved contrast and spacing */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  font-size: 15px; /* Slightly increased size for better readability */
  color: #b0c4de; /* Softer, lighter accent */
}

.form-group input, .form-group select {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15); /* Lightened background for better contrast */
  color: #f0f0f0;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 128, 128, 0.15); /* Softer, muted glow */
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

/* Placeholder color for better distinction */
.form-group input::placeholder, .form-group select::placeholder {
  color: #b0b0b0; /* Softer placeholder */
}

/* Input focus state with improved background and box-shadow */
.form-group input:focus, .form-group select:focus {
  background-color: rgba(255, 255, 255, 0.25); /* Lighter background on focus */
  box-shadow: 0 0 10px rgba(0, 128, 128, 0.3); /* Softer glow */
}

/* Button styling with modern colors */
button {
  background-color: #006666; /* Muted teal */
  color: #f0f0f0;
  padding: 12px 20px;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
  background-color: #005555; /* Darker teal on hover */
  box-shadow: 0 0 15px rgba(0, 128, 128, 0.6);
}

/* Disabled button styling */
button:disabled {
  background-color: #404040;
  color: #aaa;
  box-shadow: none;
}

/* Fare estimate styling for better emphasis */
.fare-estimate {
  margin: 15px 0;
  font-weight: bold;
  color: #66cccc; /* Softer, modern accent */
  font-size: 16px;
}

/* Suggestions list with better visibility */
.suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.1); /* Lightened background */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}

.suggestions-list li {
  padding: 10px;
  color: #f0f0f0; /* Ensures text is visible */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.suggestions-list li:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Slightly more contrast on hover */
}

/* Loading spinner with modern design */
.loading-spinner {
  font-size: 16px;
  color: #f0f0f0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Map container styling with a modern border */
.ride-booking-right {
  box-shadow: 0 0 20px rgba(0, 128, 128, 0.25);
}

/* Map container tweaks for a better user experience */
.ride-booking-right .leaflet-container {
  border-radius: 12px; /* Rounded corners to match form container */
  height: 100%;
  width: 100%;
}





/* Media query for devices with a max width of 768px */
@media (max-width: 768px) {
  .ride-booking-form-container {
    flex-direction: column;
    padding: 20px; /* Reduced padding */
    gap: 20px; /* Reduced gap */
  }

  .card, .form-group input, .form-group select, button {
    padding: 15px; /* Larger touch targets */
  }

  .form-group label {
    font-size: 14px; /* Smaller font size for space */
  }

  .ride-booking-left, .ride-booking-right {
    flex-direction: column;
    width: 100%; /* Full width for smaller screens */
  }

  .ride-booking-right .leaflet-container {
    height: 250px; /* Adjust map height for smaller screens */
  }

  .nav-menu, .nav-logo a, .logout-btn, .cta-button {
    font-size: 0.9rem; /* Smaller text to fit more content */
  }

  .nav-menu li a, .logout-btn, .cta-button {
    padding: 8px 12px; /* Adjust padding for smaller screens */
  }

  .suggestions-list li {
    font-size: 0.9rem; /* Smaller font size for suggestions */
  }

  /* Adjust the layout of suggestion list for touch accessibility */
  .suggestions-list {
    max-height: 200px; /* Limit height and add scroll */
    overflow-y: auto;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
  .form-group input, .form-group select {
    padding: 10px; /* Even larger padding for easier interaction */
  }

  .nav-menu li {
    display: block; /* Stack nav items vertically */
    width: 100%; /* Full width nav items */
    text-align: center; /* Center align text */
  }

  .nav-container {
    flex-direction: column; /* Stack elements in nav vertically */
  }


/* ✅ Fix button alignment inside the container */
.voice-chat-container {
  display: flex;
  align-items: center;  /* Ensure vertical alignment */
  justify-content: center; /* Center horizontally */
  gap: 10px; /* Maintain spacing between buttons */
  margin-top: 10px;
}

/* ✅ Ensure Both Buttons Have the Same Size & Shape */
.voice-chat-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px; /* Consistent width */
  height: 50px; /* Consistent height */
  border-radius: 50px; /* Rounded shape */
  padding: 12px 20px; /* Same padding */
  font-size: 16px;
  font-weight: bold;
  gap: 10px; /* Space between icon & text */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Consistent shadow */
  transition: all 0.3s ease;
}

/* ✅ Make Sure Both Buttons Have the Same Green Color */
.voice-command-button, .lexchat-button {
  background-color: #28a745; /* Same Green */
  color: white;
  border: none;
}

.voice-command-button:hover, .lexchat-button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* ✅ Fix for Mobile Screens */
@media (max-width: 768px) {
  .voice-chat-container {
    flex-direction: row; /* Ensures buttons are in the same row */
    justify-content: center; /* Centered alignment */
    flex-wrap: wrap; /* Allows wrapping if needed */
  }

  .voice-chat-container button {
    min-width: 160px; /* Adjust width for mobile */
    height: 45px;
  }
}


}
