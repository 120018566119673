.ride-sharing-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ride-sharing-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.sharing-toggle {
  margin-bottom: 20px;
}

.sharing-toggle label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sharing-toggle input[type="checkbox"] {
  margin-right: 10px;
}

.potential-matches h3 {
  margin-bottom: 10px;
}

.potential-matches ul {
  list-style-type: none;
  padding: 0;
}

.match-item {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.match-item:hover {
  background-color: #e6f7ff;
}

.match-item.selected {
  background-color: #bae7ff;
  border-color: #1890ff;
}

.confirm-shared-ride {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.confirm-shared-ride:hover {
  background-color: #45a049;
}