:root {
  --primary-color: #0a74da;
  --secondary-color: #f1c40f;
  --background-color: #f9f9f9;
  --white-color: #ffffff;
  --dark-color: #2a2a2a;
  --font-family: 'Poppins', sans-serif;
  --greyish-color: #e0e0e0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family);
  overflow-x: hidden;
}

.home-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.video-container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}

.home-content {
  position: relative;
  z-index: 1;
  width: 90%;
  max-width: 600px;
  padding: 20px;
}

.content-card {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 15px;
  color: var(--white-color);
  text-align: center;
  backdrop-filter: blur(5px);
}

.content-card h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.content-card p {
  font-size: 1rem;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.cta-button {
  background-color: var(--secondary-color);
  color: var(--dark-color);
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(241, 196, 15, 0.4);
}

.features-section {
  padding: 4rem 1rem;
  background-color: var(--background-color);
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-item {
  background-color: var(--white-color);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-item i {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.feature-item h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: var(--dark-color);
}

.feature-item p {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
}

.footer-section {
  background: linear-gradient(135deg, var(--dark-color) 0%, #1a1a1a 100%);
  color: var(--white-color);
  padding: 4rem 1rem 2rem;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}

.footer-heading {
  color: var(--secondary-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.footer-tagline {
  color: #ccc;
  margin-bottom: 1.5rem;
}

.footer-subheading {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
}

.footer-subheading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--primary-color);
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 0.8rem;
}

.footer-links a {
  color: #ccc;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-links a:hover {
  color: var(--white-color);
  padding-left: 5px;
}

.social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.social-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.social-links i {
  font-size: 1.2rem;
  color: white;
  transition: color 0.3s ease-in-out;
}

.social-links a:hover {
  background-color: var(--primary-color);
  transform: translateY(-3px);
}

.social-links a:hover i {
  color: #fff;
}

.footer-contact p {
  margin: 0.8rem 0;
  align-items: center;
  gap: 0.8rem;
  color: #ccc;
}


.footer-bottom {
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.copyright {
  color: #888;
  margin-bottom: 1.5rem;
}

.app-badges {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.app-badges img {
  height: 60px; /* Increased to match standard app store badge size */
  transition: transform 0.3s ease;
}

.app-badges img:hover {
  transform: translateY(-3px);
}

@media (max-width: 1024px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .video-container {
    height: 70vh;
  }

  .content-card {
    padding: 1.5rem;
  }

  .content-card h1 {
    font-size: 1.75rem;
  }

  .features-section {
    padding: 3rem 1rem;
  }

  .footer-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .footer-subheading {
    font-size: 1rem;
  }

  .app-badges img {
    height: 50px;
  }
}

@media (max-width: 400px) {
  .video-container {
    height: 60vh;
  }

  .content-card {
    padding: 1rem;
  }

  .content-card h1 {
    font-size: 1.5rem;
  }

  .content-card p {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .feature-item {
    padding: 1.5rem;
  }

  .footer-section {
    padding: 3rem 1rem 1.5rem;
  }


  .social-links {
    justify-content: left;
  }

  .app-badges img {
    height: 45px;
  }
}

@media (max-width: 360px) {
  .content-card h1 {
    font-size: 1.3rem;
  }

  .cta-button {
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
  }

  .feature-item {
    padding: 1rem;
  }

  .footer-contact p {
    font-size: 0.8rem;
  }




}

