/* ✅ Fix button alignment inside the container */
.voice-chat-container {
  display: flex;
  align-items: center;  /* Ensure vertical alignment */
  justify-content: center; /* Center horizontally */
  gap: 10px; /* Maintain spacing between buttons */
  margin-top: 10px;
}

/* ✅ Ensure Both Buttons Have the Same Size & Shape */
.voice-chat-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px; /* Consistent width */
  height: 50px; /* Consistent height */
  border-radius: 50px; /* Rounded shape */
  padding: 12px 20px; /* Same padding */
  font-size: 16px;
  font-weight: bold;
  gap: 10px; /* Space between icon & text */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Consistent shadow */
  transition: all 0.3s ease;
}

/* ✅ Make Sure Both Buttons Have the Same Green Color */
.voice-command-button, .lexchat-button {
  background-color: #28a745; /* Same Green */
  color: white;
  border: none;
}

.voice-command-button:hover, .lexchat-button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* ✅ Fix for Mobile Screens */
@media (max-width: 768px) {
  .voice-chat-container {
    flex-direction: row; /* Ensures buttons are in the same row */
    justify-content: center; /* Centered alignment */
    flex-wrap: wrap; /* Allows wrapping if needed */
  }

  .voice-chat-container button {
    min-width: 160px; /* Adjust width for mobile */
    height: 45px;
  }
}
