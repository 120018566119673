/* src/DeleteAccount.css */

.delete-account-container {
    padding: 30px;
    max-width: 700px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    line-height: 1.6;
    color: #333;
    font-family: Arial, sans-serif;
}

.delete-account-container h1 {
    font-size: 2em;
    color: #222;
    margin-bottom: 15px;
    font-weight: 600;
    text-align: center;
}

.delete-account-container p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

.delete-account-container ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #555;
    font-size: 1rem;
}

.delete-account-container ul li {
    margin-bottom: 10px;
}

.delete-account-container a {
    color: #007bff;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s ease;
}

.delete-account-container a:hover {
    color: #0056b3;
    text-decoration: underline;
}

/* Add emphasis on instructions and important details */
.delete-account-container strong {
    color: #222;
    font-weight: bold;
}

/* Divider styling for sectioning */
.delete-account-container h1::after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background-color: #007bff;
    margin: 8px auto;
    border-radius: 2px;
}
