.voice-command-container {
  margin-top: 20px;
  text-align: center;
}

.voice-command-container button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.voice-command-container button:hover {
  background-color: #45a049;
}

.voice-command-container button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.voice-command-container p {
  margin-top: 10px;
  font-style: italic;
}