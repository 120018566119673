/* Container for the entire tracking section */
.live-tracking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 20px;
  background: linear-gradient(135deg, #2c3e50, #34495e);
  color: #ecf0f1;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  height: 90vh;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
}

.live-tracking-container h2 {
  text-align: center;
  font-size: 32px;
  color: #ecf0f1;
  margin-bottom: 20px;
}

/* Flexbox container for map and ride details side by side */
.tracking-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  flex-grow: 1;
}

/* Map section and ride details will have equal width */
.map-container {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  flex: 1;
}

/* Ride details panel with proper spacing */
.ride-details-panel {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  color: #ecf0f1;
  overflow-y: auto;
  flex: 1;
}

/* Title styling inside the ride details */
.ride-details-panel h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #66ccff;
}

/* Info text in the ride details */
.ride-details-panel p {
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Icons inside the ride details */
.ride-details-panel svg {
  color: #2ecc71;
}

/* Progress bar section */
.progress-bar {
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  height: 8px;
  overflow: hidden;
}

/* Progress bar fill */
.progress-bar span {
  display: block;
  background-color: #66ccff;
  height: 100%;
  border-radius: 10px;
}

/* Distance and trip status */
.distance, .trip-status {
  margin-top: 15px;
  font-size: 18px;
  color: #bdc3c7;
}

/* Error and loading messages */
.loading, .error-message {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #bdc3c7;
}

.error-message {
  color: #e74c3c;
}

/* SOS Button */
.sos-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 12px 16px;
  border-radius: 50%;
  font-size: 18px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.sos-button:hover {
  background-color: #ff3333;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .live-tracking-container {
    height: auto; /* Adjust height for smaller screens */
  }

  .tracking-content {
    flex-direction: column;
  }

  .map-container, .ride-details-panel {
    width: 100%;
    height: 50vh;
  }

  .sos-button {
    font-size: 16px;
    padding: 10px 14px;
    top: 15px;
    right: 15px;
  }
}

/* Mobile screen adjustments */
@media (max-width: 768px) {
  .live-tracking-container {
    padding: 15px;
  }

  .live-tracking-container h2 {
    font-size: 24px; /* Smaller heading for mobile screens */
  }

  .tracking-content {
    gap: 15px;
  }

  .map-container, .ride-details-panel {
    height: 40vh; /* Reduce height for smaller mobile screens */
  }

  .ride-details-panel h3 {
    font-size: 20px;
  }

  .ride-details-panel p {
    font-size: 14px;
  }

  .progress-bar span {
    height: 6px;
  }
}

/* Very small screens like mobile phones */
@media (max-width: 480px) {
  .live-tracking-container {
    padding: 10px;
  }

  .live-tracking-container h2 {
    font-size: 20px; /* Even smaller heading for very small screens */
  }

  .tracking-content {
    gap: 10px;
  }

  .map-container, .ride-details-panel {
    height: 35vh; /* Further reduce height for very small screens */
  }

  .ride-details-panel h3 {
    font-size: 18px;
  }

  .ride-details-panel p {
    font-size: 12px;
  }

  .progress-bar span {
    height: 4px;
  }

  .sos-button {
    font-size: 14px;
    padding: 8px 10px;
  }
}
