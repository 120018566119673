.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #76c7c0;
  text-align: center;
  line-height: 1.5em;
  color: white;
}
