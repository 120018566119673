/* Root Colors & Typography */
:root {
  --primary-color: #2563eb;
  --secondary-color: #1e40af;
  --background-light: #f8fafc;
  --text-dark: #1e293b;
  --text-light: #ffffff;
}

/* Security Page Layout */
.security-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 2rem 1rem;
  font-family: system-ui, -apple-system, sans-serif;
}

/* Security Header */
.security-header {
  text-align: center;
  margin-bottom: 3rem;
  padding: 2rem 0;
  border-bottom: 2px solid #e2e8f0;
}

.security-header h1 {
  font-size: 2.5rem;
  color: var(--text-dark);
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: center;
}

.security-subtitle {
  color: #64748b;
  font-size: 1.2rem;
  text-align: center;
}

/* Security Section */
.security-section {
  margin: 2rem 0;
}

.security-card {
  background: white;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
}

/* Icon Styling */
.icon-container {
  font-size: 3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Security Grid */
.security-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  text-align: center;
  padding: 1rem;
}

.security-item {
  padding: 1.5rem;
  border-left: 4px solid var(--primary-color);
  background: var(--background-light);
  border-radius: 0.5rem;
}

.security-item h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: var(--text-dark);
}

.security-item p {
  font-size: 1rem;
  color: #4a5568;
}

/* Status Badge */
.status-badge {
  display: inline-block;
  background: var(--primary-color);
  color: white;
  padding: 0.4rem 1rem;
  border-radius: 1rem;
  font-size: 0.9rem;
  margin-top: 1rem;
  text-align: center;
}

/* Driver Vetting Section */
.driver-vetting {
  margin: 3rem 0;
  text-align: center;
}

.driver-vetting h2 {
  font-size: 2rem;
  color: var(--text-dark);
  margin-bottom: 1rem;
}

.process-steps {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  margin-top: 2rem;
  text-align: center;
}

/* Process Step Card */
.process-step {
  padding: 1.5rem;
  border: 2px solid #e2e8f0;
  border-radius: 1rem;
  position: relative;
  background: white;
  text-align: center;
}

/* Step Number */
.step-number {
  width: 2.5rem;
  height: 2.5rem;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 auto 1rem;
}

/* Emergency Section */
.emergency-section {
  margin: 3rem 0;
  text-align: center;
}

.emergency-card {
  background: #fff7ed;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}

/* Emergency Feature List */
.feature-list {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  margin: 2rem 0;
  text-align: center;
}

.feature-item {
  padding: 1rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Safety Demo Button */
.demo-button {
  background: var(--primary-color);
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
}

.demo-button:hover {
  background: var(--secondary-color);
  transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .security-header h1 {
    font-size: 2.2rem;
  }

  .security-card {
    padding: 1.5rem;
  }

  .security-item {
    padding: 1rem;
  }

  }
