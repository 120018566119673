/* src/components/TermsConditions.css */
:root {
  --background-color: #f8fafc;
  --surface-color: #ffffff;
  --border-color: #e2e8f0;
  --radius-md: 8px;
  --shadow-sm: 0 1px 3px rgba(0,0,0,0.12);
  --transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.legal-container {
  max-width: 1280px;
  margin: 2rem auto;
  padding: 0 1.5rem;
}

.legal-header {
  text-align: center;
  padding: 3rem 0;
  border-bottom: 2px solid var(--border-color);
  margin-bottom: 2rem;
}

.legal-header h1 {
  font-size: 2.5rem;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.meta-info {
  display: flex;
  gap: 2rem;
  justify-content: center;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.legal-content-wrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 3rem;
}

.toc-sidebar {
  position: sticky;
  top: 8rem;
  align-self: start;
  background: var(--surface-color);
  padding: 1.5rem;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  height: fit-content;
}

.toc-sidebar h3 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.toc-sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.toc-link {
  color: var(--text-secondary);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border-radius: 6px;
  transition: var(--transition);
}

.toc-link:hover {
  background: var(--background-color);
  color: var(--primary-color);
}

.toc-link:focus {
  outline: 2px solid var(--primary-color);
  outline-offset: 2px;
}

.policy-section {
  background: var(--surface-color);
  padding: 2rem;
  border-radius: var(--radius-md);
  margin-bottom: 2rem;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.section-icon {
  color: var(--primary-color);
  font-size: 1.5rem;
}

.styled-list {
  padding-left: 1.5rem;
}

.styled-list li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.6;
}

.styled-list li::before {
  content: '•';
  color: var(--primary-color);
  font-weight: 700;
  position: absolute;
  left: 0;
}

.pricing-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.price-card {
  background: var(--background-color);
  padding: 1.5rem;
  border-radius: var(--radius-md);
  border-left: 4px solid var(--primary-color);
  transition: var(--transition);
}

.price-card:hover {
  transform: translateY(-3px);
}

.dispute-process {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.process-step {
  text-align: center;
  padding: 1.5rem;
  background: var(--background-color);
  border-radius: var(--radius-md);
  transition: var(--transition);
}

.process-step:hover {
  transform: translateY(-3px);
}

.step-number {
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  color: var(--surface-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-weight: 600;
}

.compliance-section {
  text-align: center;
  padding: 2rem 0;
  margin-top: 3rem;
}

.compliance-badges {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.compliance-badges img {
  height: 60px;
  width: auto;
  max-width: 150px;
  transition: var(--transition);
  filter: grayscale(100%);
}

.compliance-badges img:hover {
  filter: grayscale(0%);
  transform: translateY(-3px);
}

.scroll-top-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-color);
  color: var(--surface-color);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
  z-index: 1000;
}

.scroll-top-btn:hover {
  background: var(--secondary-color);
  transform: translateY(-2px);
}

@media (max-width: 1024px) {
  .legal-content-wrapper {
    grid-template-columns: 1fr;
  }

  .toc-sidebar {
    position: static;
      z-index: 99;
  }
}

@media (max-width: 768px) {
  .legal-header h1 {
    font-size: 2rem;
    flex-direction: column;
  }

  .meta-info {
    flex-direction: column;
    gap: 0.5rem;
  }

  .pricing-details,
  .dispute-process {
    grid-template-columns: 1fr;
  }
}

@media print {
  .legal-container {
    max-width: 100%;
    padding: 0;
  }

  .toc-sidebar,
  .scroll-top-btn {
    display: none;
  }

  .policy-section {
    box-shadow: none;
    border: 1px solid #ddd;
    page-break-inside: avoid;
  }

  .compliance-badges {
    justify-content: flex-start;
  }




}