/* LanguageSwitcher.css */
.language-switcher {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.language-switcher button {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: bold;
}

.language-switcher button:hover {
  background-color: #3498db;
  color: white;
}

.language-switcher button.active {
  background-color: #2c3e50;
  color: white;
  border-color: #2c3e50;
}

/* ContrastMode.css */
.contrast-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

.slider.round {
  border-radius: 34px;
}
