/* Main Container */
.features-hub-container {
  padding: 30px;
  background-color: #f4f4f9; /* Light background for contrast */
}

/* Grid Layout for Feature Cards */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); /* Slightly larger min-width for better scaling */
  gap: 25px; /* Larger gap for better spacing */
  padding: 20px;
}

/* Card Styles */
.card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Stronger shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card:hover {
  transform: translateY(-5px); /* Slight lift effect */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Deeper shadow on hover */
}

.card-header {
  font-size: 22px;
  font-weight: bold;
  color: #4CAF50; /* Keep the custom header color */
  margin-bottom: 15px;
  text-align: center;
}

.card-content {
  padding: 20px 0;
  font-size: 16px;
  color: #666;
  flex-grow: 1; /* Ensure content takes up available space */
}

/* Button Styling */
.button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  text-align: center;
  width: 100%;
}

.button:hover {
  background-color: #45a049;
  transform: scale(1.03); /* Slight scale effect on hover */
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Ensure the text elements inside the card are styled properly */
.card h3, .card p {
  margin: 0 0 10px;
  font-weight: normal;
}

.text-green-500 {
  color: #4CAF50;
}

.text-red-500 {
  color: #ff4b4b;
}
