/* Card styling */
.card {
  background: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 20px;
}

.card-header {
  margin-bottom: 20px;
}

.card-title {
  font-size: 1.25rem;
  color: #0056b3;
}