.features-hub-container {
  display: flex;
  height: 100vh; /* Full height of the screen */
  background-color: #f9f9f9;
}
/* Shared container for both pages */
.page-container {
  display: flex;
  height: 100vh; /* Full height of the screen */
  background-color: #f9f9f9;
  position: relative; /* Required if you want an absolutely positioned handle */
  overflow: hidden;   /* Hide overflow from the hidden sidebar if you want */
}

.vertical-nav {
  width: 250px;
  background-color: #34495e;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 99;
}

.vertical-nav button {
  background-color: #2c3e50;
  color: #fff;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vertical-nav button:hover {
  background-color: #1abc9c;
}

.vertical-nav .active {
  background-color: #1abc9c;
}

/* Slide sidebar out of view when hidden */
.vertical-nav.hidden {
  transform: translateX(-250px);
}


.tab-content {
  flex-grow: 1;          /* Take remaining space */
  padding: 30px;         /* Consistent spacing */
  background-color: #fff;
  border-left: 1px solid #ddd;
  overflow-y: auto;
  transition: margin-left 0.3s ease, width 0.3s ease;
  width: calc(100% - 250px); /* If the sidebar is 250px */
}

.tab-content.full-width {
  margin-left: 0;
  width: 100%;
}


/* Responsive behavior for smaller screens */
@media (max-width: 768px) {
  .features-hub-container {
    flex-direction: column;
  }

  .vertical-nav {
    width: 100%; /* Make sidebar take full width */
    padding: 10px;
  }





  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
    }

    .vertical-nav {
      width: 100%;
      padding: 10px;
      transform: translateX(0); /* If you want it to remain visible on mobile */
    }

      /* 📌 Fix Gap Issue Below Content */
      .tab-content {
        padding-bottom: 10px !important; /* 🔥 Ensures bottom nav does not overlap content */
        padding-bottom: 10px !important; /* 🔥 Ensures bottom nav does not overlap content */
        margin-bottom: 0px !important;   /* 🔥 Fix extra space issue */
        width: 100%;
      }

    .sidebar-handle {
      top: 1px;
      left: 10px;
      transform: none;
    }
  }

}
