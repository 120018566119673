/* Centered register container with full viewport height */
.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e2a38, #283945); /* Modern gradient background */
}

/* Register box styling with glassmorphism effect */
.register-box {
  background-color: rgba(255, 255, 255, 0.15); /* Slightly more visible glass effect */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  backdrop-filter: blur(12px); /* Increased blur for better readability */
  width: 100%;
  max-width: 350px;
  text-align: center;
  color: #ffffff; /* White text for readability */
  color: #4caf50;
}

/* Form input styling with improved text contrast */
.register-input {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border-radius: 8px;
  border: none;
  background-color: rgba(255, 255, 255, 0.25); /* Slightly increased opacity */
  color: #ffffff; /* Ensure text inside input is clearly visible */
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  outline: none;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
}

/* Placeholder text visibility improved */
.register-input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Lighter text for better contrast */
  font-weight: 400;
}

/* Input hover and focus effect */
.register-input:focus {
  background-color: rgba(255, 255, 255, 0.35); /* Lighter background on focus */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated shadow on focus */
}

/* Register button styling with modern colors */
.register-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50; /* Modern green color */
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Register button hover and active states */
.register-btn:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-4px); /* Subtle lift on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}

.register-btn:active {
  transform: translateY(2px); /* Button press effect */
  box-shadow: none;
}

/* Error message styling */
.error-message {
  color: #ff4d4f; /* Red for errors */
  font-size: 0.9rem;
  margin-bottom: 15px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .register-box {
    padding: 30px;
    width: 90%;
  }
}
