/* Mobile-First Approach */
.driver-management-container {
  width: 100%;
  padding: 20px;
  background-color: #f0f4f8;
  min-height: 100vh;
  box-sizing: border-box;
}

.tab-menu {
  position: relative;  /* Ensures the button stays inside */
  z-index: 1;          /* Prevents overlapping */
}

.tab-menu button {
  width: 100%;
  max-width: 300px;
  padding: 15px;
  margin: 0 5px;
  border: none;
  border-radius: 25px;
  background-color: #e0e0e0;
  color: #333;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-menu button.active-tab {
  background-color: #4CAF50;
  color: white;
}

.tab-menu button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
  opacity: 0.7;
}

.tab-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.driver-header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #2c3e50;
}

.driver-item {
  padding: 15px;
  margin-bottom: 12px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.ride-request-popup {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 400px;
  background-color: #fff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

/* Mobile-Specific Adjustments */
@media (max-width: 768px) {
  .driver-management-container {
    padding: 15px;
  }

  .tab-menu button {
    font-size: 14px;
    padding: 12px;
    border-radius: 20px;
  }

  .tab-content {
    padding: 15px;
  }

  .driver-header {
    font-size: 18px;
  }

  .driver-item {
    padding: 12px;
  }

  .driver-item h3 {
    font-size: 16px;
  }

  .driver-item p {
    font-size: 14px;
  }

  .button-group button {
    padding: 8px 12px;
    font-size: 14px;
  }
}

/* Map Container Responsive Styling */
.map-container {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
  margin: 15px 0;
}

@media (min-width: 768px) {
  .map-container {
    height: 400px;
  }
}