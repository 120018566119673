/* Center the confirmation page */
.booking-confirmation-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style the heading */
.booking-confirmation-container h1 {
  font-size: 28px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 20px;
  text-align: center;
}

/* Style the confirmation details */
.confirmation-details p {
  font-size: 18px;
  color: #495057;
  margin: 10px 0;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

/* Bold the labels */
.confirmation-details p strong {
  color: #212529;
}

/* Add some margin for the last item */
.confirmation-details p:last-child {
  margin-bottom: 0;
}
