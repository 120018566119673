/* Booking History Container */
.booking-history-container {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #1e2a38; /* Darker background for contrast */
  color: #e0e0e0; /* Softer text color */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Added subtle shadow */
  display: flex; /* Flexbox for side-by-side layout */
  gap: 20px;
}

/* Scrollable containers for bookings and chart */
.booking-cards-container, .insights-container {
  flex: 1;
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 600px; /* Set a max height */
}

/* Booking Cards Container */
.booking-cards-container {
  display: flex;
  flex-direction: column; /* Stack the cards vertically */
  gap: 20px;
}

/* Individual Booking Card */
.booking-card {
  background-color: #24303e; /* Darker card background */
  border: 1px solid #3e4a58; /* Softer border */
  padding: 20px;
  border-radius: 12px;
  text-align: left;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow for depth */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth hover effects */
}

/* Card hover effect */
.booking-card:hover {
  transform: scale(1.05); /* Slight scaling for interactivity */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.25); /* Enhanced shadow on hover */
}

/* Card Header */
.booking-card h3 {
  margin-bottom: 15px;
  color: #66ccff; /* Accent color */
  font-size: 20px;
}

/* Card Text */
.booking-card p {
  margin: 10px 0;
  font-size: 14px;
  color: #b0c4de; /* Softer color for description text */
}

/* Insights Container */
.insights-container {
  background-color: #2a3d50; /* Darker background for insights */
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15); /* Softer shadow */
  color: #e0e0e0;
}

/* Insights Header */
.insights-container h3 {
  margin-bottom: 20px;
  font-size: 22px;
  color: #66ccff; /* Accent color */
}

/* Spending Chart Container */
.spending-chart {
  padding: 20px;
  background-color: #24303e;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .booking-history-container {
    flex-direction: column; /* Stack containers vertically on small screens */
  }
}






.review-section {
  margin-top: 15px;
}

.review-section h4 {
  margin-bottom: 10px;
}

.review-section .rating {
  font-size: 24px;
}

.review-section textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.review-section button {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.review-section button:hover {
  background-color: #218838;
}

.filter-section {
  margin-bottom: 20px;
}

.filter-section select {
  padding: 5px;
  margin-left: 10px;
  border-radius: 4px;
}