.careers-container {
  max-width: 1000px;
  margin: auto;
  padding: 40px 20px;
  text-align: center;
}

.careers-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 30px;
}

.section-heading {
  font-size: 2rem;
  color: #333;
  margin: 40px 0 20px;
}

.job-listings {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.job-card {
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  width: 80%;
}

.job-card h3 {
  color: #0056b3;
}

.job-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  color: #666;
  margin: 10px 0;
}

.job-skills {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
}

.job-skills span {
  background: #eef2ff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #333;
}

.apply-button {
  margin-top: 15px;
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.apply-button:hover {
  background: #0056b3;
}

.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
}

.benefit-card {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  width: 45%;
}

.benefit-card i {
  font-size: 2rem;
  color: #007bff;
}

.benefit-card h3 {
  margin-top: 10px;
  color: #333;
}

.benefit-card p {
  font-size: 0.9rem;
  color: #555;
}

.hiring-steps {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
}

.hiring-steps li {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .job-card {
    width: 100%;
  }

  .benefit-card {
    width: 100%;
  }
}
