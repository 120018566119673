/* NavBar.css */
.navbar {
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-wrap: nowrap;  /* Prevents wrapping */
}

.nav-logo {
  display: flex;
  align-items: center;
  z-index: 1001;
  flex-shrink: 0;
  max-width: 70%;
}

.nav-logo-link {
  font-size: 1.3rem;
  font-weight: 700;
  color: white;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.3s ease;
}

.nav-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: all 0.3s ease;
}


/* Ensure proper layout on smaller screens */
@media (max-width: 480px) {
  .nav-container {
    flex-direction: row;
    flex-wrap: nowrap; /* Prevents wrapping */
    align-items: center;
  }

  .nav-menu {
    display: none; /* Hide the menu by default */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #333;
    padding: 1rem;
    text-align: center;
  }

  .nav-menu.active {
    display: flex;
  }

  .nav-link {
    width: 100%;
    font-size: 1rem;
  }

  .mobile-menu-icon {
    display: block;
  }
}






.nav-link:hover {
  transform: scale(1.05);
}

.logout-btn {
  background-color: #e74c3c;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logout-btn:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

.mobile-menu-icon {
  display: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
  flex-shrink: 0;
  margin-left: auto;
  padding: 0.5rem;
}







/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 0.8rem 1rem;
  }

  .mobile-menu-icon {
    display: block;
  }

  .nav-menu {
    position: fixed;
    top: 56px;
    left: -100%;
    height: calc(100vh - 56px);
    width: 100%;
    flex-direction: column;
    background-color: #333;
    padding: 2rem 1rem;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    gap: 1.5rem;
    z-index: 999;
    overflow-y: auto;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-link, .logout-btn {
    width: 100%;
    padding: 1rem;
    text-align: center;
    font-size: 1.1rem;
  }

  .logout-btn {
    margin-top: 1rem;
    width: auto;
    padding: 1rem 2rem;
  }
}

/* OnePlus 10 and similar devices (412px-430px) */
@media (max-width: 430px) {
  .navbar {
    padding: 0.6rem 0.8rem;
  }

  .nav-menu {
    top: 52px;
    height: calc(100vh - 52px);
    padding: 1.5rem 0.8rem;
  }

.mobile-menu-icon {
  font-size: 1.5rem; /* Slightly increased for better visibility */
  padding: 0.6rem; /* Adjusted padding */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px; /* Ensures uniform height */
  width: 48px;  /* Ensures uniform width */
}

/* Ensure nav links and logout button have the same height */
.nav-link, .logout-btn {
  font-size: 0.95rem; /* Keep consistent */
  padding: 0.9rem;
  height: 48px; /* Same height as menu icon */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Adjust logout button padding for better alignment */
.logout-btn {
  margin-top: 0.9rem;
  padding: 0.9rem 1.8rem;
  height: 48px; /* Ensuring uniform height */
}
}

/* Small devices (360px-412px) */
@media (max-width: 390px) {
  .navbar {
    padding: 0.5rem 0.6rem;
  }

  .nav-menu {
    top: 48px;
    height: calc(100vh - 48px);
    padding: 1.2rem 0.6rem;
  }


.mobile-menu-icon {
  font-size: 1.5rem;  /* Slightly increased for better visibility */
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px; /* Ensures uniform height */
  width: 44px;  /* Ensures uniform width */
}

/* Ensure nav links and logout button have the same height */
.nav-link, .logout-btn {
  font-size: 1rem; /* Increased to match icon proportionally */
  padding: 0.8rem;
  height: 44px; /* Same height as menu icon */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


  .logout-btn {
    padding: 0.8rem 1.6rem;
  }
}

/* Animation for menu items */
.nav-link, .logout-btn {
  animation: fadeIn 0.3s ease-in-out forwards;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Better touch targets */
@media (pointer: coarse) {
  .nav-link, .logout-btn, .mobile-menu-icon {
    min-height: 44px;
    min-width: 44px;
  }
}