/* Page Content Styles */
.content-section {
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
  margin: 2rem auto;
  max-width: 1200px;
}

.page-content {
  line-height: 1.6;
  color: #444;
}

/* Grid Styles */
.features-grid, .security-grid, .accessibility-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
}

.feature-card, .security-card, .option-card {
  padding: 1.5rem;
  border-radius: 10px;
  background: var(--background-color);
  text-align: center;
}

/* Typography */
h1 { font-size: 2.5rem; margin-bottom: 2rem; }
h2 { font-size: 1.8rem; margin: 2rem 0 1rem; color: var(--primary-color); }
h3 { font-size: 1.4rem; margin: 1rem 0; }

/* Legal Pages */
.legal-content section {
  margin-bottom: 3rem;
}

.legal-content ul {
  padding-left: 2rem;
  margin: 1rem 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-section {
    padding: 1rem;
    margin: 1rem;
  }

  h1 { font-size: 2rem; }
  h2 { font-size: 1.5rem; }
}

@media (max-width: 480px) {
  .features-grid, .security-grid {
    grid-template-columns: 1fr;
  }
}