/* Fullscreen Centered Form */
.driver-registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #1e2a38, #283945); /* Modern gradient background */
  overflow: hidden; /* Prevents scrolling issues */
}

/* Centered Registration Box */
.driver-registration-box {
  background-color: rgba(255, 255, 255, 0.1); /* Glass-like transparency */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  backdrop-filter: blur(10px); /* Creates glass effect */
  width: 100%;
  max-width: 350px;
  text-align: center;
  color: #fff; /* White text for dark background */
}

/* Form Title */
.driver-registration-box h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 15px;
}

/* Form Input Fields */
.form-control {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border-radius: 8px;
  border: none;
  background-color: rgba(255, 255, 255, 0.3); /* Increased opacity */
  color: #ffffff; /* Ensure text is visible */
  font-size: 16px;
  font-weight: 500;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure placeholder text is visible */
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Higher opacity */
  font-weight: 400;
}

/* Input focus effect */
.form-control:focus {
  background-color: rgba(255, 255, 255, 0.5); /* Lighter background for focus */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


/* Input Focus Effect */
.form-control:focus {
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Submit Button */
.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Button Hover & Active States */
.submit-btn:hover {
  background-color: #45a049;
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.submit-btn:active {
  transform: translateY(2px);
  box-shadow: none;
}

/* Responsive Design for Mobile */
@media (max-width: 480px) {
  .driver-registration-box {
    width: 90%;
    padding: 25px;
  }

  .form-control {
    padding: 10px;
  }

  .submit-btn {
    padding: 10px;
  }
}
