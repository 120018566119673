.promotions-container {
  max-width: 600px;
  margin: 50px auto;
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 8px;
}

.promotions-container h2, .promotions-container h3 {
  margin-bottom: 20px;
}

.promo-offers p {
  background-color: #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.promotions-container form {
  display: flex;
  flex-direction: column;
}

.promotions-container input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.promotions-container button {
  background-color: #28a745;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.promo-confirmation {
  margin-top: 15px;
  color: green;
  font-weight: bold;
}
