.preferred-drivers-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.preferred-drivers-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.search-drivers {
  display: flex;
  margin-bottom: 20px;
}

.search-drivers input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

/* ✅ Modern Search Button (Fixed Alignment) */
.search-drivers button {
  padding: 10px 15px;
  margin-bottom: 20px;
  background-color: #3498db;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 42px; /* Matches input field height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-results, .preferred-drivers-list {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 20px;
}

.search-results h3, .preferred-drivers-list h3 {
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

li:last-child {
  border-bottom: none;
}

li button {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

li button:hover {
  background-color: #d32f2f;
}