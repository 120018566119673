    /* Base Color Variables */
    :root {
      /* Light Mode */
      --light-bg: #f0f2f5;
      --light-text: #333333;
      --light-primary: #007bff;
      --light-border: #dddddd;

      /* High Contrast Mode (Professional Blue Accent) */
  --high-contrast-bg: #000000;
  --high-contrast-text: #ffffff;
  --high-contrast-border: #ffb300;
  --high-contrast-highlight: #ffd54f;
    }

    /* High Contrast Mode Applied to Body */
    body.high-contrast {
      background-color: var(--high-contrast-bg);
      color: var(--high-contrast-text);
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      transition: background-color 0.4s ease, color 0.4s ease;
    }

    /* High Contrast Links */
    body.high-contrast a {
      color: var(--high-contrast-highlight);
      text-decoration: underline;
      font-weight: bold;
    }
    body.high-contrast a:hover {
      color: var(--high-contrast-bg);
      background-color: var(--high-contrast-highlight);
    }

    /* High Contrast Buttons */
    body.high-contrast button {
      background-color: var(--high-contrast-border);
      color: var(--high-contrast-bg);
      border: 2px solid var(--high-contrast-highlight);
      padding: 12px 20px;
      font-weight: 700;
      cursor: pointer;
      border-radius: 5px;
      transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s;
    }
    body.high-contrast button:hover {
      background-color: var(--high-contrast-highlight);
      color: var(--high-contrast-text);
    }

    /* High Contrast Inputs & Selects */
    body.high-contrast input,
    body.high-contrast select {
      background: var(--high-contrast-border);
      color: var(--high-contrast-text);
      border: 2px solid var(--high-contrast-highlight);
      padding: 10px;
      font-size: 16px;
      border-radius: 4px;
    }
    body.high-contrast input:focus,
    body.high-contrast select:focus {
      outline: 3px solid var(--high-contrast-highlight);
    }

    /* High Contrast Cards */
    body.high-contrast .card {
      background: var(--high-contrast-border);
      color: var(--high-contrast-text);
      border: 2px solid var(--high-contrast-highlight);
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
    }

    /* High Contrast Headings */
    body.high-contrast h1,
    body.high-contrast h2,
    body.high-contrast h3 {
      color: var(--high-contrast-highlight);
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    /* High Contrast Tables */
    body.high-contrast table {
      border-collapse: collapse;
      width: 100%;
      border: 2px solid var(--high-contrast-highlight);
    }
    body.high-contrast table th,
    body.high-contrast table td {
      padding: 12px;
      border: 2px solid var(--high-contrast-highlight);
      text-align: left;
    }
    body.high-contrast table th {
      background: var(--high-contrast-border);
      color: var(--high-contrast-highlight);
      font-weight: bold;
    }

    /* High Contrast Scrollbar */
    body.high-contrast ::-webkit-scrollbar {
      width: 12px;
    }
    body.high-contrast ::-webkit-scrollbar-track {
      background: var(--high-contrast-bg);
    }
    body.high-contrast ::-webkit-scrollbar-thumb {
      background: var(--high-contrast-highlight);
      border-radius: 6px;
    }

    /* Focus Outline */
    body.high-contrast *:focus {
      outline: 3px solid var(--high-contrast-highlight);
      outline-offset: 3px;
    }

    /* Container for the Contrast Toggle */
    .contrast-mode-container {
      text-align: center;
      padding: 20px;
      margin: 20px 0;
    }

    /* Contrast Toggle Button */
    .contrast-toggle {
      display: inline-flex;
      align-items: center;
      background: var(--light-primary);
      color: var(--light-text);
      padding: 12px 24px;
      border-radius: 50px;
      font-size: 16px;
      font-weight: 600;
      border: none;
      cursor: pointer;
      transition: background-color 0.3s ease, transform 0.2s;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
    .contrast-toggle.active {
      background: var(--high-contrast-highlight);
      color: var(--high-contrast-bg);
    }
    .contrast-toggle span {
      margin-left: 10px;
    }
    .contrast-toggle:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    /* Icon Styling */
    .contrast-toggle svg {
      font-size: 22px;
    }
