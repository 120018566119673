/* src/components/Booking/UniqueFeatures.css */

/* Grid Layout for Feature Cards */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px; /* Space between the grid items */
  padding: 20px;
}

/* Card Styles */
.card {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-header {
  font-size: 20px;
  font-weight: bold;
  color: #4CAF50; /* Custom color for headers */
  margin-bottom: 10px;
}

.card-content {
  padding: 15px;
  font-size: 14px;
  color: #333;
}

.card h3 {
  font-weight: bold;
  margin-bottom: 10px;
}

.card ul {
  list-style: none;
  padding: 0;
}

.card ul li {
  background-color: #f1f1f1;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

/* Switch styling */
.switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  position: relative;
}

.switch input {
  display: none;
}

.switch span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 34px;
  background-color: #ccc;
  transition: background-color 0.3s;
}

.switch span:before {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.switch input:checked + span {
  background-color: #4CAF50;
}

.switch input:checked + span:before {
  transform: translateX(26px);
}

/* Button Styling */
.button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.text-green-500 {
  color: #4CAF50;
}

.text-red-500 {
  color: red;
}
