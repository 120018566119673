/* Ensure styles apply only within ProfileSettings */
.profile-settings-page {
  display: flex;
  height: 100vh;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

/* Sidebar Toggle Button (Mobile) */
.profile-settings-page .sidebar-toggle {
  display: none;
  position: absolute;
  top: 15px;
  left: 15px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #2c3e50;
  z-index: 1001;
}

/* Sidebar Navigation */
.profile-settings-page .vertical-nav {
  width: 250px;
  background-color: #34495e;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99;
}

/* Hide Sidebar by Default on Mobile */
.profile-settings-page .vertical-nav.hidden {
  transform: translateX(-100%);
}

/* Show Sidebar when Visible */
.profile-settings-page .vertical-nav.visible {
  transform: translateX(0);
}

/* Sidebar Buttons */
.profile-settings-page .vertical-nav button {
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-settings-page .vertical-nav button:hover {
  background-color: #1abc9c;
}

.profile-settings-page .vertical-nav .active {
  background-color: #1abc9c;
}

/* Main Content */
.profile-settings-page .tab-content {
  flex-grow: 1;
  padding: 30px;
  background-color: white;
  overflow-y: auto;
  transition: width 0.3s ease;
  width: calc(100% - 250px);
}

/* Full-width Content when Sidebar is Hidden */
.profile-settings-page .tab-content.full-width {
  width: 100%;
}

/* Bottom Navigation (Only for Mobile) */
.profile-settings-page .bottom-nav {
  display: none;
}

/* 🌍 Mobile Layout */
@media (max-width: 768px) {
  .profile-settings-page {
    flex-direction: column;
  }

  /* Show Sidebar Toggle Button on Mobile */
  .profile-settings-page .sidebar-toggle {
    display: block;
  }

  /* Hide Sidebar by Default */
  .profile-settings-page .vertical-nav {
    display: none;
  }

  /* Ensure Sidebar is Fixed when Open */
  .profile-settings-page .vertical-nav.visible {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    z-index: 1000;
    background-color: #34495e;
  }

  /* Bottom Navigation Bar */
  .profile-settings-page .bottom-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #34495e;
    padding: 10px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
  }

  /* Bottom Navigation Buttons */
  .profile-settings-page .bottom-nav button {
    flex: 1;
    max-width: 90px;
    margin: 0 5px;
    background-color: #2c3e50;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    text-align: center;
  }

  /* Active & Hover Effects */
  .profile-settings-page .bottom-nav button:hover,
  .profile-settings-page .bottom-nav .active {
    background-color: #1abc9c;
    transform: scale(1.05);
  }

  /* Ensure Content Leaves Space for Bottom Nav */
  .profile-settings-page .tab-content {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0px;
    width: 100%;

  }
}
