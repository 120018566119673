/* Slider Container */
.slider-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  color: #2c3e50;
}

/* Modern Slider */
.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 10px;
  outline: none;
  transition: background 0.3s ease-in-out;
  cursor: pointer;
}

/* Slider Thumb (Knob) for Chrome, Safari, Edge */
.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  background: #03A9F4;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
}

.custom-slider::-webkit-slider-thumb:hover {
  transform: scale(1.2);
  background: #0288D1;
}

.custom-slider::-webkit-slider-thumb:active {
  transform: scale(1.3);
  background: #01579B;
}

/* Slider Thumb (Knob) for Firefox */
.custom-slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  background: #03A9F4;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
}

.custom-slider::-moz-range-thumb:hover {
  transform: scale(1.2);
  background: #0288D1;
}

.custom-slider::-moz-range-thumb:active {
  transform: scale(1.3);
  background: #01579B;
}

/* Display Value */
.slider-value {
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  min-width: 40px;
  text-align: center;
}

/* Accessibility Focus */
.custom-slider:focus {
  outline: 2px solid #03A9F4;
  box-shadow: 0 0 6px rgba(3, 169, 244, 0.5);
}
