/* 🌍 General Styles */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif;
}

/* 🖥️ Desktop Layout */
.page-container {
  display: flex;
  height: 100vh;
  background-color: #f9f9f9;
}

/* 📌 Hide Sidebar Completely on Mobile */
.vertical-nav {
  width: 250px;
  background-color: #34495e;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

/* Hide Sidebar on Mobile */
@media (max-width: 768px) {
  .vertical-nav {
    display: none !important; /* Completely Hide */
  }
}

/* Sidebar Buttons */
.vertical-nav button {
  background-color: #2c3e50;
  color: white;
  padding: 15px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.vertical-nav button:hover {
  background-color: #1abc9c;
}

.vertical-nav .active {
  background-color: #1abc9c;
}

/* 📌 Main Content */
.tab-content {
  flex-grow: 1;
  padding: 30px;
  background-color: white;
  overflow-y: auto;
}

/* 📌 Bottom Navigation - Mobile Only */
.bottom-nav {
  display: none; /* Hidden by Default */
}

/* 🌍 Mobile Layout */
@media (max-width: 768px) {
  /* Hide Sidebar Completely */
  .vertical-nav {
    display: none !important;
  }

  /* Enable Bottom Navigation */
  .bottom-nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #34495e;
    padding: 12px 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
  }

  /* Bottom Navigation Buttons */
  .bottom-nav button {
    flex: 1;
    max-width: 90px;
    margin: 0 5px;
    background-color: #2c3e50;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-align: center;
  }

  /* Hover & Active */
  .bottom-nav button:hover,
  .bottom-nav .active {
    background-color: #1abc9c;
    transform: scale(1.05);
  }

  /* Ensure Content Doesn't Overlap Bottom Nav */
  .tab-content {
    padding: 20px;
    margin-bottom: 0px; /* Leaves Space */
    text-align: center;
  }
}
