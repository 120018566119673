:root {
  --primary: #2563eb;
  --primary-hover: #1d4ed8;
  --secondary: #4f46e5;
  --background: #f8fafc;
  --surface: #ffffff;
  --border: #e2e8f0;
  --text-primary: #1e293b;
  --text-secondary: #475569;
  --radius-md: 8px;
  --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.12);
  --transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.privacy-container {
  max-width: 1280px;
  margin: 2rem auto;
  padding: 0 1.5rem;
}

/* Scroll Progress Bar */
.scroll-progress {
  height: 4px;
  background: var(--primary);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: width 0.3s ease;
}

/* Page Content */
.privacy-content-wrapper {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 3rem;
}

/* Sidebar Navigation */
.privacy-nav {
  position: sticky;
  top: 8rem;
  align-self: start;
  background: var(--surface);
  padding: 1.5rem;
  border-radius: var(--radius-md);
  box-shadow: var(--shadow-sm);
  height: fit-content;
}

.privacy-nav h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--text-primary);
}

.privacy-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.privacy-nav a {
  color: var(--text-secondary);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  border-radius: 6px;
  transition: var(--transition);
}

.privacy-nav a:hover {
  background: var(--background);
  color: var(--primary);
}

.privacy-nav a:focus {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

/* Main Content */
.policy-section {
  background: var(--surface);
  padding: 2rem;
  border-radius: var(--radius-md);
  margin-bottom: 2rem;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .privacy-content-wrapper {
    grid-template-columns: 1fr;
  }

  .privacy-nav {
    position: static;
    z-index: 99;
  }
}

@media (max-width: 768px) {
  .privacy-nav {
    display: none; /* Hide sidebar on mobile */
  }
}

/* Scroll to Top Button */
.scroll-top-btn {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary);
  color: var(--surface);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
  z-index: 1000;
}

.scroll-top-btn:hover {
  background: var(--secondary);
  transform: translateY(-2px);
}
