/* Profile.css */
:root {
  --primary-color: #2c3e50;
  --secondary-color: #3498db;
  --accent-color: #e74c3c;
  --background-color: #f8f9fa;
  --text-color: #2c3e50;
  --border-color: #ecf0f1;
  --shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: var(--shadow);
}

.profile-title {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.profile-picture {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid var(--secondary-color);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.profile-picture:hover {
  transform: scale(1.03);
}

.profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.change-picture-label {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(52, 152, 219, 0.9);
  color: white;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.change-picture-label:hover {
  background: var(--primary-color);
}

.change-picture-label input {
  display: none;
}

.profile-details {
  width: 100%;
  max-width: 500px;
}

.profile-field {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  background: var(--background-color);
  border-radius: 0.8rem;
  padding: 1rem;
  border: 1px solid var(--border-color);
  transition: box-shadow 0.3s ease;
}

.profile-field:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.field-icon {
  color: var(--secondary-color);
  font-size: 1.2rem;
  min-width: 30px;
}

.profile-field label {
  font-weight: 500;
  color: var(--text-color);
  margin-right: 1rem;
  min-width: 120px;
}

.profile-field input {
  flex: 1;
  padding: 0.5rem;
  border: none;
  background: transparent;
  color: var(--text-color);
  font-size: 1rem;
  outline: none;
}

.profile-field input:disabled {
  color: #95a5a6;
  background: transparent;
}

.profile-actions {
  margin-top: 2rem;
}

.edit-btn, .save-btn {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 0.8rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.edit-btn {
  background: var(--secondary-color);
  color: white;
}

.save-btn {
  background: var(--accent-color);
  color: white;
}

.edit-btn:hover, .save-btn:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow);
}

.edit-btn:hover {
  background: #2980b9;
}

.save-btn:hover {
  background: #c0392b;
}

@media (max-width: 768px) {
  .profile-container {
    width: 90%;
    padding: 1.5rem;
    margin: 1rem auto;
  }

  .profile-field {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 1rem;
  }

  .profile-field label {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .field-icon {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

.loading {
  text-align: center;
  padding: 2rem;
  color: var(--text-color);
  font-size: 1.2rem;
}

.error-message {
  color: var(--accent-color);
  text-align: center;
  padding: 2rem;
  font-weight: 500;
}