/* Fullscreen Centered Container */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e2a38, #283945); /* Dark themed background */
}

/* Login Box with Glassmorphism */
.login-box {
  background-color: rgba(255, 255, 255, 0.15);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(12px);
  width: 100%;
  max-width: 350px;
  text-align: center;
  color: #ffffff;
}

/* Title Styling */
.login-title {
  font-size: 22px;
  font-weight: bold;
  color: #4caf50; /* Green title */
  margin-bottom: 20px;
}

/* Form Spacing & Alignment */
.login-box form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Ensures even spacing */
  width: 100%;
}

/* Input Fields */
.login-input {
  width: 100%;
  padding: 14px;
  border-radius: 8px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.25);
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
  outline: none;
  transition: all 0.3s ease;
}

/* Placeholder Styling */
.login-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}

/* Input Focus Effect */
.login-input:focus {
  background-color: rgba(255, 255, 255, 0.35);
  border: 2px solid #4CAF50; /* Green border */
}

/* Login Button */
.login-btn {
  width: 100%;
  padding: 14px;
  background-color: #4CAF50;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Button Hover */
.login-btn:hover {
  background-color: #45a049;
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Button Click Effect */
.login-btn:active {
  transform: translateY(2px);
  box-shadow: none;
}

/* Error Message */
.error-message {
  color: #ff4d4f;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .login-box {
    padding: 30px;
    width: 90%;
  }
}
