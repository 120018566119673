/* Centered login container with full viewport height */
.driver-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #1e2a38, #283945); /* Modern gradient background */
}

/* Login box styling with glassmorphism effect */
.driver-login-box {
  background-color: rgba(255, 255, 255, 0.1); /* Glass-like transparency */
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Stronger shadow for depth */
  backdrop-filter: blur(10px); /* Creates glass effect */
  width: 100%;
  max-width: 350px;
  text-align: center;
  color: #fff; /* White text for dark background */
}

/* Form input styling */
.login-input {
  width: 100%;
  padding: 12px;
  margin: 12px 0;
  border-radius: 8px;
  border: none;
  background-color: rgba(255, 255, 255, 0.2); /* Semi-transparent input background */
  color: #fff; /* White input text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  outline: none;
  transition: box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

/* Input hover and focus effect */
.login-input:focus {
  background-color: rgba(255, 255, 255, 0.3); /* Lighter background on focus */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Elevated shadow on focus */
}

/* Login button styling with modern colors */
.login-btn {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50; /* Modern green color */
  border: none;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Login button hover and active states */
.login-btn:hover {
  background-color: #45a049; /* Darker green on hover */
  transform: translateY(-4px); /* Subtle lift on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Larger shadow on hover */
}

.login-btn:active {
  transform: translateY(2px); /* Button press effect */
  box-shadow: none;
}

/* Error message styling */
.error-message {
  color: #ff4d4f; /* Red for errors */
  font-size: 0.9rem;
  margin-bottom: 15px;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .driver-login-box {
    padding: 30px;
  }

  .login-btn {
    font-size: 14px;
    padding: 10px;
  }

  .login-input {
    font-size: 14px;
  }
}
