/* General styles for the entire app */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
}

/* Navigation bar styling */
nav {
  background-color: #333;
  padding: 15px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Container for nav content */
.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo in the navigation */
.nav-logo a {
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.nav-logo a:hover {
  color: #4CAF50;
}

/* Main navigation menu */
.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-menu li {
  margin-right: 20px;
}

.nav-menu li a {
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.nav-menu li a:hover {
  background-color: #4CAF50;
  transform: scale(1.05);
}

/* Logout button styling */
.logout-btn {
  background-color: #e74c3c;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.logout-btn:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .nav-menu {
    display: none; /* Hide the menu by default */
    flex-direction: column;
    width: 100%;
  }

  .mobile-menu {
    display: block;
    cursor: pointer;
    color: white;
  }

  .mobile-menu.open .nav-menu {
    display: flex;
    flex-direction: column;
  }

  .nav-menu li {
    margin: 10px 0;
  }
}


/* Other existing styles in App.css */

/* Driver Management and Map styles */
.map-container {
  height: 400px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.driver-management-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f0f4f8;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tab-menu {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.tab-menu button {
  padding: 12px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-menu button:hover {
  background-color: #4CAF50;
  color: white;
}

.tab-content {
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
