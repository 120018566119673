/* General Styling */
.blog-container {
  max-width: 900px;
  margin: auto;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
}

/* Header Styling */
.blog-header {
  text-align: center;
  margin-bottom: 30px;
}

.blog-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0056b3;
}

.blog-header p {
  font-size: 1.1rem;
  color: #666;
  margin-top: 5px;
}

/* Category Filter */
.category-filter {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.category-filter button {
  background: #f1f1f1;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-filter .active {
  background: #007bff;
  color: white;
}

.category-filter button:hover {
  background: #007bff;
  color: white;
}

/* Blog Post List */
.blog-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-post {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: transform 0.2s ease;
}

.blog-post:hover {
  transform: translateY(-5px);
}

.blog-post h2 {
  font-size: 1.5rem;
  color: #0056b3;
  margin-bottom: 5px;
}

.post-meta {
  font-size: 0.9rem;
  color: #666;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.blog-post p {
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

.read-more {
  display: inline-block;
  margin-top: 10px;
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.read-more:hover {
  color: #0056b3;
}

/* Newsletter Section */
.newsletter-cta {
  text-align: center;
  background: #eef2ff;
  padding: 25px;
  margin-top: 40px;
  border-radius: 10px;
}

.newsletter-cta h3 {
  font-size: 1.8rem;
  color: #0056b3;
}

.newsletter-cta p {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}

.subscribe-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.subscribe-form input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  width: 60%;
}

.subscribe-button {
  background: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.subscribe-button:hover {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-container {
    padding: 20px;
  }

  .blog-header h1 {
    font-size: 2rem;
  }

  .blog-header p {
    font-size: 1rem;
  }

  .category-filter {
    flex-wrap: wrap;
    justify-content: center;
  }

  .blog-post {
    padding: 15px;
  }

  .read-more {
    font-size: 0.9rem;
  }

  .subscribe-form {
    flex-direction: column;
  }

  .subscribe-form input {
    width: 100%;
  }
}
