/* Dashboard.css */
:root {
  --primary-color: #4361ee;
  --secondary-color: #3f37c9;
  --background: #f8f9fa;
  --text-primary: #2b2d42;
  --text-secondary: #6c757d;
  --border-color: #e9ecef;
}

.dashboard-container {
  padding: 1.5rem;
  background-color: var(--background);
  min-height: 100vh;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 2rem;
}

.dashboard-header h1 {
  font-size: 1.75rem;
  color: var(--text-primary);
  font-weight: 600;
  margin: 0;
}

.dashboard-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  max-width: 1200px;
  margin: 0 auto;
}

.card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border: 1px solid var(--border-color);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.card-header h2 {
  font-size: 1.25rem;
  color: var(--text-primary);
  margin: 0;
}

.card-icon {
  font-size: 1.5rem;
  color: var(--primary-color);
}

.input-group {
  margin-bottom: 1rem;
}

.input-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.input-group input,
.input-group select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.input-group input:focus,
.input-group select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(67, 97, 238, 0.1);
}

.primary-btn {
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s ease;
  width: 100%;
}

.primary-btn:hover {
  background: var(--secondary-color);
}

.document-upload {
  margin-bottom: 1.5rem;
}

.file-input {
  display: block;
  margin-bottom: 1rem;
}

.file-input input[type="file"] {
  display: none;
}

.file-input span {
  display: block;
  padding: 0.75rem;
  background: var(--background);
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-input:hover span {
  border-color: var(--primary-color);
}

.document-list {
  border-top: 1px solid var(--border-color);
  padding-top: 1rem;
}

.document-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--border-color);
}

.doc-info {
  flex: 1;
}

.doc-type {
  font-weight: 500;
  color: var(--text-primary);
}

.doc-expiry {
  display: block;
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.ride-item {
  padding: 1rem;
  background: var(--background);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.ride-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.status-badge {
  padding: 0.25rem 0.5rem;
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 500;
}

.status-badge.completed {
  background: #e3fcef;
  color: #0a5e41;
}

.status-badge.pending {
  background: #fff3e0;
  color: #e65100;
}

.action-buttons {
  display: grid;
  gap: 0.75rem;
}

.action-btn {
  padding: 0.75rem;
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-btn:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .dashboard-container {
    padding: 1rem;
  }

  .card {
    padding: 1rem;
  }

  .dashboard-header h1 {
    font-size: 1.5rem;
  }
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}