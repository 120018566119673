/* General Page Layout */
.content-section {
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
}

.page-header {
  text-align: center;
  margin-bottom: 3rem;
}

.page-header h1 {
  font-size: 2.8rem;
  color: #2c3e50;
  font-weight: bold;
}

.page-header p {
  font-size: 1.2rem;
  color: #555;
  max-width: 800px;
  margin: auto;
}

/* Sections */
.section-title {
  font-size: 2.2rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Accessibility Features Grid */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  justify-content: center;
  padding: 0 1rem;
}

.feature-card {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0,0,0,0.15);
}

.feature-icon {
  font-size: 3rem;
  color: #2980b9;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.6rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.feature-card p {
  font-size: 1rem;
  color: #4a5568;
  line-height: 1.6;
}

/* Compliance Standards */
.accessibility-standards {
  background: white;
  padding: 2.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.accessibility-standards p {
  font-size: 1.1rem;
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.compliance-list {
  list-style: none;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 2rem;
}

.compliance-list li {
  margin-bottom: 0.5rem;
}

/* CTA Button */
.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 1.8rem;
  background-color: #2980b9;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #1a5276;
}

.cta-icon {
  margin-right: 0.5rem;
  font-size: 1.4rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .content-section {
    padding: 1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .feature-card {
    padding: 1.5rem;
  }

  .section-title {
    font-size: 1.8rem;
  }
}
