.support-container {
  max-width: 600px;
  margin: 50px auto;
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
}

.support-container h2 {
  margin-bottom: 20px;
}

.support-container form {
  display: flex;
  flex-direction: column;
}

.support-container label {
  margin-bottom: 10px;
  font-weight: bold;
}

.support-container input, .support-container textarea {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.support-container button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.support-confirmation {
  text-align: center;
  font-size: 18px;
  color: green;
}
