.about-container {
  max-width: 1000px;
  margin: auto;
  padding: 40px 20px;
  text-align: center;
}

.about-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0056b3;
  margin-bottom: 30px;
}

.stats-section {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 40px;
}

.stat-item {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  width: 30%;
}

.stat-item h3 {
  font-size: 2rem;
  color: #0056b3;
}

.stat-item p {
  font-size: 1rem;
  color: #666;
}

.section-heading {
  font-size: 2rem;
  color: #333;
  margin: 40px 0 20px;
}

.leadership-grid {
  display: flex;
  justify-content: space-around;
  gap: 30px;
}

.leader-card {
  background: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 45%;
}

.leader-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.position {
  font-weight: bold;
  color: #007bff;
}

.bio {
  font-size: 0.9rem;
  color: #555;
}

.eco-metrics {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.eco-card {
  background: #f1f1f1;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  width: 45%;
}

.eco-card i {
  font-size: 2rem;
  color: green;
}

.eco-card h3 {
  font-size: 1.5rem;
  margin-top: 10px;
}

.eco-card p {
  font-size: 0.9rem;
  color: #444;
}

/* Responsive Design */
@media (max-width: 768px) {
  .stats-section,
  .leadership-grid,
  .eco-metrics {
    flex-direction: column;
    align-items: center;
  }

  .stat-item,
  .leader-card,
  .eco-card {
    width: 80%;
    margin-bottom: 20px;
  }
}